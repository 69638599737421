/*
  Thank you antd guys 
*/
.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
  
  span {
    color: #fff;
    font-weight: 900;
    font-size: medium;
    line-height: 2;
  }  
}

@primary-color: #1B36DB;@secondary-color: #2529bd;@link-color: #636FCC;@heading-color: #34394A;@heading-color-secondary: #ADB5CC;@text-color: #34394A;@text-color-secondary: #ADB5CC;@label-color: #34394A;