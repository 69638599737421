@import '~antd/dist/antd.less';

@font-family: -apple-system, Oxygen, 'Oxygen', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';

#root {
  height: 100%;
}

@primary-color: #1B36DB;@secondary-color: #2529bd;@link-color: #636FCC;@heading-color: #34394A;@heading-color-secondary: #ADB5CC;@text-color: #34394A;@text-color-secondary: #ADB5CC;@label-color: #34394A;